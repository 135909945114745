import React from "react";
import { Col, Row } from "reactstrap";

import { SummaryCard } from "../../components/SummaryCard";
import { formatKey } from "../../utils/commonUtils";
import { FIELD_MAPPING } from "./Config";
import { LabeledDataItem } from "../../components/LabeledDataItem";

const SummarySection = ({ title, cards, colProps }) => {
    return (
        <div className=" my-2 p-2">
            {title && (
                <h5 className="font-size-18 font-weight-bold text-center mb-2 text-black">
                    {title}
                </h5>
            )}
            <Row>
                {cards.map((card, index) => (
                    <SummaryCard
                        key={index}
                        title={card.title}
                        titleClassName="font-weight-medium"
                        colProps={colProps}
                        cardBodyProps={{ className: "p-4 " }}
                        cardProps={{
                            className: "border rounded mx-2"
                        }}
                    >
                        {Object.entries(card.item).map(([key, value], idx) => (
                            <div key={idx}>
                                <Col md={12}>
                                    <LabeledDataItem
                                        title={FIELD_MAPPING[key] || formatKey(key)}
                                        desc={value || 0}
                                        titleColProps={{ xs: 9, md: 9 }}
                                        descColProps={{ xs: 3, md: 3 }}
                                        descClass={"text-end"}
                                    />
                                </Col>
                            </div>
                        ))}
                    </SummaryCard>
                ))}
            </Row>
        </div>
    );
};

export default SummarySection;
