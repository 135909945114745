import React from "react";

import InfoCard from "./InfoCard";
import JobDetailsCard from "./JobDetailsCard";
import JobApplicants from "./JobApplicants";
import JobRatAndReviews from "./JobRatAndReviews";
import { JobsStatus } from "../../constants";

const JobDetails = ({ data, isSuccess }) => {
    return (
        <div className="my-4">
            <InfoCard
                seeker={data?.user}
                provider={data?.assignee}
                job={data}
                status={data?.status}
            />
            <JobDetailsCard data={isSuccess && data} />

            {(JobsStatus.getDisplayTextKey(4) || JobsStatus.getDisplayTextKey(8)) && (
                <JobApplicants data={data?.jobApplicants} />
            )}
            <JobRatAndReviews
                providerRating={data?.providerRating}
                seekerRating={data?.seekerRating}
            />
        </div>
    );
};

export default JobDetails;
