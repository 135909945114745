import React, { useMemo } from "react";
import { Helmet } from "react-helmet";
import { Container } from "reactstrap";

import { Heading } from "../../components/Heading";
import { PageHeadings } from "../../constants/PageHeading";
import { PageTitles } from "../../constants";
import SummarySection from "./SummarySection";
import { GetUsersAnalytics, GetJobsAnalytics } from "../../api/api.service";
import { getKey, MODULE_KEYS } from "../../api/api.keys";
import { useRequestProcessor } from "../../hooks/useRequestProcessors";
import { hasData } from "../../utils/commonUtils";
import { LoaderFallbackUI } from "../../components/LoaderFallbackUI";
import { mapUserDataToCards } from "./Config";

const Dashboard = () => {
    const { useFetch } = useRequestProcessor();

    const { data: jobData, isFetching: isJobDataFetching } = useFetch(
        getKey([MODULE_KEYS.DASHBOARD_USER_OVERVIEW]),
        async ({ signal }) => await GetUsersAnalytics({ params: {}, signal })
    );

    const { data: userData, isFetching: isUserDataFetching } = useFetch(
        getKey([MODULE_KEYS.DASHBOARD_JOB_OVERVIEW]),
        async ({ signal }) => await GetJobsAnalytics({ params: {}, signal })
    );

    const jobOverviewCards = useMemo(() => {
        if (userData) {
            return mapUserDataToCards(userData, jobData);
        }
        return [];
    }, [userData, jobData]);

    if (isJobDataFetching || isUserDataFetching || !hasData(userData) || !hasData(jobData)) {
        return (
            <div style={{ height: "70vh" }}>
                <LoaderFallbackUI
                    isLoader={isJobDataFetching || isUserDataFetching}
                    className={"h-100"}
                />
            </div>
        );
    }

    return (
        <div className="page-content">
            <Helmet>
                <title>{PageTitles.DASHBOARD} </title>
            </Helmet>
            <Container fluid>
                <Heading heading={PageHeadings.DASHBOARD} />

                <SummarySection
                    title="Users & Jobs Overview"
                    cards={jobOverviewCards}
                    colProps={{ sm: 12, md: 6, lg: 6, xl: 4, xxl: 4 }}
                />
            </Container>
        </div>
    );
};

export default Dashboard;
