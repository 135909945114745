import React from "react";
import { LabeledDataItem } from "../../components/LabeledDataItem";
import { CardTitle, Col, Row } from "reactstrap";
import { SummaryCard } from "../../components/SummaryCard";
import { renderStars } from "../../utils/ratingUtils";

const HEADINGS = {
    OVER_ALL_RATING: "Overall Rating: ",
    RELIABILITY: "Reliability: ",
    TIMELINESS: "Timeliness: ",
    COMMUNICATION: "Communication: ",
    REVIEW: "Review: "
};

const JobRatAndReviews = ({ providerRating, seekerRating }) => {
    let seekerAvgRating =
        (seekerRating?.communicationRating +
            seekerRating?.timelinessRating +
            seekerRating?.reliabilityRating) /
            3 || 0;
    let providerAvgRating =
        (providerRating?.communicationRating +
            providerRating?.timelinessRating +
            providerRating?.reliabilityRating) /
            3 || 0;

    return (
        <SummaryCard title="Rating and Reviews" titleClassName={"font-size-18 fw-bold"}>
            <CardTitle className="font-size-14 fw-bold">Seeker</CardTitle>
            <div style={{ color: "black" }} className="py-2 px-3">
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.OVER_ALL_RATING}
                            desc={
                                <div>
                                    {seekerAvgRating} {renderStars(seekerAvgRating)}{" "}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                        />
                    </Col>
                </Row>
            </div>
            <div style={{ color: "black" }} className="py-2  px-3">
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.RELIABILITY}
                            titleClass="fw-normal clr-theme-primary"
                            desc={
                                <div>
                                    {seekerRating?.reliabilityRating}{" "}
                                    {renderStars(seekerRating?.reliabilityRating)}{" "}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.TIMELINESS}
                            titleClass="fw-normal clr-theme-primary"
                            desc={
                                <div>
                                    {seekerRating?.timelinessRating}{" "}
                                    {renderStars(seekerRating?.timelinessRating)}{" "}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.COMMUNICATION}
                            titleClass="fw-normal clr-theme-primary"
                            desc={
                                <div>
                                    {seekerRating?.communicationRating}{" "}
                                    {renderStars(seekerRating?.communicationRating)}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.REVIEW}
                            desc={seekerRating?.comment}
                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                            titleClass="fw-normal clr-theme-primary"
                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                            rowClass="my-1"
                        />
                    </Col>
                </Row>
            </div>

            <CardTitle className="font-size-14 fw-bold">Provider</CardTitle>

            <div style={{ color: "black" }} className="py-2 px-3">
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.OVER_ALL_RATING}
                            desc={
                                <div>
                                    {providerAvgRating} {renderStars(providerAvgRating)}{" "}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                        />
                    </Col>
                </Row>
            </div>
            <div style={{ color: "black" }} className="py-2  px-3">
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.RELIABILITY}
                            titleClass="fw-normal clr-theme-primary"
                            desc={
                                <div>
                                    {providerRating?.reliabilityRating}{" "}
                                    {renderStars(providerRating?.reliabilityRating)}{" "}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.TIMELINESS}
                            titleClass="fw-normal clr-theme-primary"
                            desc={
                                <div>
                                    {providerRating?.timelinessRating}{" "}
                                    {renderStars(providerRating?.timelinessRating)}{" "}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 4, lg: 6 }}
                            descColProps={{ xs: 7, md: 8, lg: 6 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.COMMUNICATION}
                            titleClass="fw-normal clr-theme-primary"
                            desc={
                                <div>
                                    {providerRating?.communicationRating}{" "}
                                    {renderStars(providerRating?.communicationRating)}
                                </div>
                            }
                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={6}>
                        <LabeledDataItem
                            title={HEADINGS.REVIEW}
                            desc={providerRating?.comment}
                            titleColProps={{ xs: 5, md: 5, lg: 6 }}
                            titleClass="fw-normal clr-theme-primary"
                            descColProps={{ xs: 7, md: 7, lg: 6 }}
                            rowClass="my-1"
                        />
                    </Col>
                </Row>
            </div>
        </SummaryCard>
    );
};

export default JobRatAndReviews;
